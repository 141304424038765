import React,{ useState,useEffect }  from "react"
import Slider from "react-slick";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  arrows: false,
  adaptiveHeight: true,
  // customPaging: function(i) {
  //   return (
  //     <span></span>
  //   );
  // },
  responsive: [
    {
      breakpoint: 980,
      settings: {
        variableWidth: false,
        centerMode: false
      }
    }
  ]
};

  function FeaturedCarsdPosts({ featuredCardsGroup,cardsHeading }){
    const breakpoints = useBreakpoint();
    const [showFilter,setShowFilter] = useState(false)

    const updatePosts=(e)=>{
      if(featuredCardsGroup.length>1){
        setShowFilter(true)
      }else{
        setShowFilter(false)
      }
    }
    useEffect(() => {
      updatePosts()
    }, []);

    const ConditionalWrap = ({condition, wrap, children}) => condition ? wrap(children) : children;

    const SliderDiv = () => {
      return (
        <>
        {featuredCardsGroup.length
        ? featuredCardsGroup.map((cards, index) => (
          <div key={index}>
          <a href={`${cards.featuredCardsPost.featuredCardDetails.featuredCardUrl}`} >
             <div className="full-width-flex-container">

                <div className="slick-featured-card-content-container">
                   <div className="slick-featured-card-content-holder">

                      <div className="slick-featured-card-title-holder">
                         <h2>
                            {cards.featuredCardsPost.title}</h2>
                      </div>

                      <div className="slick-featured-card-text-holder">
                            <h3 dangerouslySetInnerHTML={{ __html: cards.featuredCardsPost.content }}/>
                      </div>
                      {cards.featuredCardsPost.featuredCardDetails.featuredCardButtonText ? (
                      <div className="slick-featured-card-link-holder">
                          <span>
                                  {cards.featuredCardsPost.featuredCardDetails.featuredCardButtonText}
                               </span>
                      </div>
                         ) : null}
                    </div>

                </div>
                <div className="slick-featured-card-image-holder">
                        <img src={`/htbcontent/uploads/${cards.featuredCardsPost.featuredImage.mediaDetails.file}`} alt={cards.featuredCardsPost.featuredImage.altText} title={cards.featuredCardsPost.featuredImage.title}/>
                     </div>
                </div>
          </a>
          </div>
          ))
        : null}
        </>
      );
    }

  return (
    <>
    { cardsHeading ?
      <section className="content white-background">
        <div className="eighty-spacer"></div>
        <div className="content-block">
          <div className="centered-content-container">
            <div className="centered-title-holder">
              <h3>{cardsHeading}</h3>
            </div>
          </div>
        </div>
        <div className="eighty-spacer"></div>
      </section>
      : null
    }
    {showFilter ?
    <Slider className="slick-featured-card-container" {...slickSettings}>
      {featuredCardsGroup.length
        ? featuredCardsGroup.map((cards, index) => (
          <div key={index}>
          <a href={`${cards.featuredCardsPost.featuredCardDetails.featuredCardUrl}`}>
             <div className="full-width-flex-container slick-flex-mob specialist-car-finance">

                <div className="slick-featured-card-content-container">
                   <div className="slick-featured-card-content-holder">

                      <div className="slick-featured-card-title-holder">
                         <h2>
                            {cards.featuredCardsPost.title}</h2>
                      </div>

                      <div className="slick-featured-card-text-holder">
                            <h3 dangerouslySetInnerHTML={{ __html: cards.featuredCardsPost.content }}/>
                      </div>
                      {cards.featuredCardsPost.featuredCardDetails.featuredCardButtonText ? (
                      <div className="slick-featured-card-link-holder">
                          <span>
                                  {cards.featuredCardsPost.featuredCardDetails.featuredCardButtonText}
                               </span>
                      </div>
                         ) : null}
                    </div>

                </div>
                <div className="slick-featured-card-image-holder">
                        <img src={`/htbcontent/uploads/${cards.featuredCardsPost.featuredImage.mediaDetails.file}`} alt={cards.featuredCardsPost.featuredImage.altText} title={cards.featuredCardsPost.featuredImage.title}
                        />
                     </div>
                </div>
          </a>
          </div>
          ))
        : null}
        </Slider>
        :

        <>
        {breakpoints.sm ?
        <div className="slick-featured-card-container slick-initialized slick-slider" style={{visibility:"visible"}}>
        <div className="slick-list draggable" >
            <div className="slick-track" style={{opacity:"1",width:"420px",transform:"translate3d(-25px, 0px, 0px)"}}>
                <div className="slick-slide slick-current slick-active slick-center"
         data-slick-index="0" aria-hidden="false" style={{width:"420px"}}>
                    <div>
                    <SliderDiv/>
                    </div>
                </div>
            </div>
        </div>

        </div>
        :
        <div className="slick-featured-card-container slick-initialized slick-slider" style={{visibility:"visible"}}>
        <div className="slick-list draggable" >
            <div className="slick-track" style={{opacity:"1",width:"5000px",transform:"translate3d(268px, 0px, 0px)"}}>
                <div className="slick-slide slick-current slick-active slick-center"
         data-slick-index="0" aria-hidden="false">
                    <div>
                    <SliderDiv/>
                    </div>
                </div>
            </div>
        </div>

        </div>
      }
      </>
      }
<div className="eighty-spacer"></div>
          </>
  )
  }


export default FeaturedCarsdPosts
